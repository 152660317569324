import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-grid-system"
import RichContentTextBlock from "./richContentTextBlock"
import { LinkButtonSecondary } from "./button"
import Logo from "./logo"

const ProductLaunchBlock = ({
  text,
  link,
  logo,
  image,
  backgroundImage,
  product,
}) => (
  <Wrapper>
    <CustomBackground>
      <Img fluid={backgroundImage.fluid} />
    </CustomBackground>
    <Container>
      <Row>
        <Col lg={6}>
          <Img fluid={image.fluid} />
        </Col>
        <Col lg={6}>
          <Details>
            <Logo logo={logo} />
            <RichContentTextBlock html={text} />
            <LinkButtonSecondary
              to={`applicazioni/rivestimenti-murali/${product.slug}/`}
            >
              Scopri di più
            </LinkButtonSecondary>
          </Details>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

const Details = styled.div`
    margin-top: 3rem;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6rem;
`

const CustomBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .gatsby-image-wrapper {
    height: 100%;
  }
`

export default ProductLaunchBlock
