import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Heading, HeadingSmall } from "./typography"
import RichContentTextBlock from "./richContentTextBlock"
import { LinkButton } from "./button"
import titleBorder from "../images/title-border-primary.svg"
import { Box, Container, Grid } from "@theme-ui/components"

const InfoBlock = ({ title, subtitle, text, link }) => (
  <Container variant="small">
    <Grid columns={[1, 2]} gap={[0, 32, 64]}>
      <Box>
        <CustomHeading>{title}</CustomHeading>
        <HeadingSmall color={DesignTokens.colors.primary[500]}>
          {subtitle}
        </HeadingSmall>
      </Box>
      <Box
        sx={{
          marginTop: ["1rem", "1rem", "2rem"],
          "& > *": {
            marginBottom: "2rem",
            "&:last-child": {
              marginBottom: 0,
            },
          },
        }}
      >
        <RichContentTextBlock html={text} />
        {link && (
          <LinkButton to={`/${link.page.slug}/`}>{link.name}</LinkButton>
        )}
      </Box>
    </Grid>
  </Container>
)

const CustomHeading = styled(Heading)`
  padding-top: 2rem;
  margin-bottom: 1rem;
  background-image: url(${titleBorder});
  background-repeat: no-repeat;
`

export default InfoBlock
