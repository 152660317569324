import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import RichContentTextBlock from "./richContentTextBlock"
import { LanguageContext } from "../locales/langContext"
import Img from "gatsby-image"
import { Box, Container, Grid, Text, Heading } from "@theme-ui/components"
import { Link } from "gatsby"
import { getPagePath } from "./pathUtils"

const HighlightBlock = ({
  title,
  subtitle,
  image,
  text,
  highlight,
  alignment,
  page,
}) => (
  <Box
    sx={{
      position: "relative",
      backgroundColor: highlight ? "primary" : "white",
    }}
  >
    {/* IMAGE */}
    <Container
      variant="fullWidth"
      sx={{
        position: ["relative", "relative", "absolute"],
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Grid columns={[1, 1, 2]} gap={0} sx={{ height: "100%" }}>
        {!alignment && <Box></Box>}
        <Box
          sx={{
            ".gatsby-image-wrapper": {
              height: "100%",
            },
          }}
        >
          {image && <Img fluid={image.fluid} />}
        </Box>
        {alignment && <Box></Box>}
      </Grid>
    </Container>
    {/* TEXT */}
    <Grid
      columns={[1, 1, 2]}
      gap={[0, 32, 32, 164]}
      sx={{ position: "relative" }}
    >
      {alignment && <Box></Box>}
      <Box sx={{ py: highlight ? [3, 4, 7] : [4, 6, 10], px: [3, 5] }}>
        {title && (
          <Heading
            sx={{
              color: highlight ? "light" : "primary",
              fontSize: [5, 5, 5, 6],
              mb: 2,
              hyphens: "auto",
            }}
          >
            {title}
          </Heading>
        )}
        {subtitle && (
          <Text
            as="p"
            sx={{
              fontSize: 4,
              color: highlight ? "light" : "primary",
              fontWeight: "normal",
            }}
          >
            {subtitle}
          </Text>
        )}
        <Box mb={4}>
          <RichContentTextBlock
            html={text}
            color={highlight ? "white" : null}
          />
        </Box>
        <StyledLink to={getPagePath(page)} highlight={highlight}>
          <LanguageContext.Consumer>
            {t => t.discoverMore}
          </LanguageContext.Consumer>
        </StyledLink>
      </Box>
      {!alignment && <Box></Box>}
    </Grid>
  </Box>
)

const StyledLink = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 3rem;
  color: ${props =>
    props.highlight ? "white" : DesignTokens.colors.primary[500]};
  ${props =>
    props.highlight
      ? `background-image: linear-gradient(90deg, white, white)`
      : `background-image: linear-gradient(90deg, ${DesignTokens.colors.primary[500]}, ${DesignTokens.colors.primary[500]})`};
  background-size: 36px 1px;
  background-position: 0 50%;
  background-repeat: no-repeat;
`

const StyledBox = styled.div`
  margin-top: 2rem;
  padding: 3rem;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default HighlightBlock
