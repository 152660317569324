import React from "react"
import styled from "styled-components"
import { LanguageContext } from "../locales/langContext"
import InfoBlock from "./infoBlock"
import ImageBlock from "./imageBlock"

const ApplicationsBlock = ({ title, subtitle, text, applications }) => {
  return (
    <LanguageContext.Consumer>
      {t => (
        <Wrapper>
          <InfoBlock title={title} subtitle={subtitle} text={text} />
          {applications.map((application, index) => (
            <ImageBlock
              title={application.title}
              text={application.summary}
              image={application.image}
              highlight={true}
              alignment={index % 2 === 0 ?  true : false}
              slug={application.slug}
              page={application}
            />
          ))}
        </Wrapper>
      )}
    </LanguageContext.Consumer>
  )
}
const Wrapper = styled.div`
`

export default ApplicationsBlock
