import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { ScreenClassRender } from "react-grid-system"
import { Heading } from "./typography"
import RichContentTextBlock from "./richContentTextBlock"
import Img from "gatsby-image"
import { LinkButtonTertiary } from "./button"
import { Box, Container } from "@theme-ui/components"
import { Link } from "gatsby"
import { getApplicationPath } from "./pathUtils"
import { LanguageContext } from "../locales/langContext"

const ImageBlock = ({
  title,
  image,
  text,
  highlight,
  alignment,
  slug,
  root,
  page,
}) => (
  <LanguageContext.Consumer>
    {t => (
      <Wrapper>
        <Container variant="small">
          <Box sx={{ mb: [0, 0, 4, 2], position: "relative", pb: [0, 0, 6] }}>
            {image && <Img fluid={image.fluid} />}
            {highlight && (
              <CustomBackground alignment={alignment}>
                {title && (
                  <Heading color={DesignTokens.colors.white}>{title}</Heading>
                )}
                <RichContentTextBlock html={text} />
                {slug && !root && (
                  <StyledLink to={getApplicationPath(page)}>
                    {t.discoverMore}
                  </StyledLink>
                )}
                {slug && root && (
                  <LinkButtonTertiary to={`/${slug}/`}>
                    {t.discoverMore}
                  </LinkButtonTertiary>
                )}
              </CustomBackground>
            )}
            {!highlight && (
              <StyledBox>
                {title && <Heading>{title}</Heading>}
                <RichContentTextBlock html={text} />
              </StyledBox>
            )}
          </Box>
        </Container>
      </Wrapper>
    )}
  </LanguageContext.Consumer>
)
const Wrapper = styled.div``

const CustomBackground = styled.div`
  position: absolute;
  color: white;
  bottom: 0rem;
  ${props => (props.alignment ? `right: 3rem;` : `left: 3rem;`)}
  padding: 2rem 2rem;
  background: ${DesignTokens.colors.primary[500]};
  width: 600px;
  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const StyledLink = styled(Link)`
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 3rem;
  background-image: linear-gradient(90deg, #ffffff, #ffffff);
  background-size: 36px 1px;
  background-position: 0 50%;
  background-repeat: no-repeat;
`

const StyledBox = styled.div`
  margin-top: 2rem;
  padding: 3rem;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default ImageBlock
