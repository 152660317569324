import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Container } from "react-grid-system"
import DesignTokens from "./designTokens"
import { Box, Grid, Text } from "@theme-ui/components"
import SplitText from "./splitText"
import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"

const NumbersBlock = ({ numbers }) => {
  const [startCount, setStartCount] = useState(false)
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView === true && startCount === false) {
      setStartCount(true)
    }
  }, [inView])

  return (
    <Wrapper>
      <CustomContainer>
        <Grid columns={[1, 3, 3, 6]} gap={[32, 16, 16, 32]} ref={ref}>
          {numbers.map(number => (
            <Box key={number.id}>
              <>
                <Number>
                  <CountUp
                    end={number.number}
                    prefix={number.prefix ? number.prefix : ""}
                    duration={3}
                    start={startCount}
                  />
                  {number.suffix ? (
                    <Text
                      sx={{
                        marginLeft: 1,
                        display: "inline",
                        color: "primary",
                        fontSize: 2,
                      }}
                      dangerouslySetInnerHTML={{ __html: number.suffix }}
                    />
                  ) : (
                    ""
                  )}
                </Number>
                <SplitText text={number.subtitle} />
              </>
            </Box>
          ))}
        </Grid>
      </CustomContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  top: -3rem;
`

const CustomContainer = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: white;
  box-shadow: 0 -26px 22px 0 rgba(0, 0, 0, 0.25);
  &::before {
    content: "";
    top: 0;
    width: 40%;
    height: 100%;
    position: absolute;
    background: white;
    left: -40%;
  }
`

const Number = styled.div`
  font-family: "Merriweather", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  font-size: 2.25rem;
  white-space: nowrap;
  color: ${DesignTokens.colors.primary[500]};
`

export default NumbersBlock
