import React from "react"
import styled from "styled-components"
import { Box, Container, Text } from "@theme-ui/components"
import { Heading } from "./typography"
import { LanguageContext } from "../locales/langContext"
import I18nSlug from "./i18nSlug"
import Img from "gatsby-image"

// Begin swiper
import SwiperCore, { Pagination, Mousewheel, A11y, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
SwiperCore.use([Mousewheel, Pagination, A11y, Autoplay])
// End swiper

const ProductsBlock = ({ title, products }) => {
  return (
    <LanguageContext.Consumer>
      {t => (
        <Wrapper>
          <Container variant="small">
            <CustomHeading>{title}</CustomHeading>
            <Box>
              <Swiper
                spaceBetween={32}
                slidesPerView={3}
                pagination={{ clickable: true }}
                loop
                autoplay={{ delay: 2500 }}
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  // when window width is >= 480px
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  // when window width is >= 640px
                  1024: {
                    slidesPerView: 3,
                  },
                }}
              >
                {products.map(product => (
                  <SwiperSlide key={product.id}>
                    <I18nSlug path={t.products} slug={product.slug}>
                      <Box
                        sx={{
                          position: "relative",
                          borderTopLeftRadius: "sm",
                          borderTopRightRadius: "sm",
                          overflow: "hidden",
                        }}
                      >
                        <Img fluid={product.image.fluid} alt="" />
                        <Box
                          sx={{
                            position: "absolute",
                            mb: 0,
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            opacity: 0.5,
                            backgroundImage:
                              "linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #000000 100%)",
                          }}
                        ></Box>
                        <Text
                          sx={{
                            color: "light",
                            fontSize: 4,
                            fontWeight: 400,
                            position: "absolute",
                            mb: 0,
                            bottom: "1.5rem",
                            left: "1.5rem",
                            textTransform: "uppercase",
                            width: "60%",
                          }}
                        >
                          {product.title}
                        </Text>
                      </Box>
                    </I18nSlug>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Container>
        </Wrapper>
      )}
    </LanguageContext.Consumer>
  )
}
const Wrapper = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: #f3f6f8;
  box-shadow: inset 0 6px 10px 0 rgba(0, 0, 0, 0.05);
  .gatsby-image-wrapper {
    transition: opacity 0.3s linear;
  }
  a:hover {
    .gatsby-image-wrapper {
      opacity: 0.7;
    }
  }
`

const CustomHeading = styled(Heading)`
  margin-bottom: 3rem;
`

export default ProductsBlock
