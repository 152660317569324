import React from "react"
import { motion } from "framer-motion"
import { Box } from "@theme-ui/components"

const container = {
  hidden: { opacity: 0, x: 10 },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.035,
    },
  },
}

const item = {
  hidden: { opacity: 0, scale: 0 },
  show: { opacity: 1, scale: 1 },
}

const SplitText = ({ text }) => (
  <motion.div variants={container} initial="hidden" animate="show">
    {text.split("").map(function (char, index) {
      return (
        <Span as="span" key={index} variants={item}>
          {char}
        </Span>
      )
    })}
  </motion.div>
)

const Span = motion.custom(Box)

export default SplitText
