import React from "react"
import styled from "styled-components"
import titleBorder from "../images/title-border-secondary.svg"

const Logo = ({ logo }) => (
  <Wrapper>
    <img src={logo.url} alt="Logo" />
  </Wrapper>
)
const Wrapper = styled.div`
  background-image: url(${titleBorder});
  background-repeat: no-repeat;
  padding-top: 2rem;
  .gatsby-image-wrapper {
    height: 50%;
  }
`

export default Logo
