import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import HeroHeader from "../components/heroHeader"
import NumbersBlock from "../components/numbersBlock"
import ProductsBlock from "../components/productsBlock"
import InfoBlock from "../components/infoBlock"
import CallToAction from "../components/callToAction"
import ImageBlock from "../components/imageBlock"
import ApplicationsBlock from "../components/applicationsBlock"
import ProductLaunchBlock from "../components/productLaunchBlock"
import { ScreenClassProvider } from "react-grid-system"
import HighlightBlock from "../components/highlightBlock"
import { getHomePath } from "../components/pathUtils"
import { HelmetDatoCms } from "gatsby-source-datocms"

const Page = ({ data: { page, site }, pageContext, location }) => {
  const locale = pageContext.locale
  const i18nPaths = page._allContentLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getHomePath(locale.locale),
    }
  })
  // console.log(page._allContentLocales, i18nPaths)

  // console.log(page)
  return (
    <ScreenClassProvider>
      <Layout
        lang={locale}
        title={page.title}
        location={location}
        i18nPaths={i18nPaths}
      >
        <HelmetDatoCms seo={page.seoMetaTags}>
          <html lang={locale} />
        </HelmetDatoCms>
        <HeroHeader
          title={page.title}
          subtitle={page.subtitle}
          heroImage={page.heroImage}
          big
        />
        <Content>
          {page.content.map((section, index) => (
            <section key={section.id}>
              {section.model.apiKey === "numbers_block" && (
                <NumbersBlock numbers={section.numbers} />
              )}
              {section.model.apiKey === "products_block" && (
                <ProductsBlock
                  title={section.title}
                  products={section.products}
                />
              )}
              {section.model.apiKey === "info_block" && (
                <InfoBlock
                  title={section.title}
                  subtitle={section.subtitle}
                  text={section.text}
                  link={section.link}
                />
              )}
              {section.model.apiKey === "image_block" && (
                <ImageBlock
                  title={section.title}
                  text={section.text}
                  image={section.image}
                  highlight={section.highlight}
                  alignment={section.rightAlignment}
                  slug={section.link.page.slug}
                  page={section.link.page}
                  root
                />
              )}
              {section.model.apiKey === "highlight_block" && (
                <HighlightBlock
                  title={section.title}
                  subtitle={section.subtitle}
                  text={section.text}
                  image={section.image}
                  highlight={section.highlight}
                  alignment={section.rightAlignment}
                  page={section.link.page}
                />
              )}
              {section.model.apiKey === "applications_block" && (
                <ApplicationsBlock
                  title={section.title}
                  subtitle={section.subtitle}
                  text={section.text}
                  applications={section.applications}
                />
              )}
              {section.model.apiKey === "product_launch_block" && (
                <ProductLaunchBlock
                  text={section.text}
                  product={section.product.slug}
                  logo={section.logo}
                  image={section.image}
                  backgroundImage={section.backgroundImage}
                />
              )}
            </section>
          ))}
        </Content>
        <CallToAction
          title={page.callToAction.title}
          backgroundImage={page.callToAction.backgroundImage}
          slug={page.callToAction.slug}
          name={page.callToAction.name}
        />
      </Layout>
    </ScreenClassProvider>
  )
}

const Content = styled.div`
  /* & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  } */
`

export default Page

export const query = graphql`
  query HomePageQuery($locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsHomePage(
      originalId: { eq: "10804801" }
      locale: { eq: $locale }
    ) {
      title
      subtitle
      id
      locale
      _allContentLocales {
        locale
      }
      heroImage {
        fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      callToAction {
        title
        backgroundImage {
          fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        slug
        name
      }
      content {
        ... on DatoCmsNumbersBlock {
          id
          model {
            apiKey
          }
          numbers {
            number
            prefix
            suffix
            subtitle
            id
          }
        }
        ... on DatoCmsProductsBlock {
          id
          model {
            apiKey
          }
          title
          products {
            title
            text
            slug
            image {
              fluid(maxWidth: 1040, imgixParams: { fm: "jpg" }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
        ... on DatoCmsApplicationsBlock {
          id
          model {
            apiKey
          }
          title
          subtitle
          text
          applications {
            title
            slug
            locale
            summary
            subtitle
            ...AllApplicationSlugLocales
            heroImage {
              fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
                ...GatsbyDatoCmsFluid
              }
            }
            image {
              fluid(maxWidth: 1280, imgixParams: { fm: "jpg" }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
        ... on DatoCmsApplicationsBlock {
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsProductLaunchBlock {
          id
          model {
            apiKey
          }
          product {
            slug
          }
          text
          image {
            fluid(maxWidth: 520, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          logo {
            url
          }
          backgroundImage {
            fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          product {
            slug
          }
        }
        ... on DatoCmsImageBlock {
          title
          text
          image {
            fluid(maxWidth: 520, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          highlight
          rightAlignment
          id
          link {
            page {
              slug
              locale
              root
              ...AllSlugLocales
              treeParent {
                slug
                root
                ...AllSlugLocales
              }
            }
            name
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsHighlightBlock {
          title
          subtitle
          text
          image {
            fluid(maxWidth: 1280, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          highlight
          rightAlignment
          id
          link {
            page {
              slug
              locale
              root
              ...AllSlugLocales
              treeParent {
                slug
                root
                ...AllSlugLocales
              }
            }
            name
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsInfoBlock {
          title
          subtitle
          text
          link {
            name
            page {
              title
              slug
              root
              ...AllSlugLocales
              treeParent {
                slug
                root
                ...AllSlugLocales
              }
            }
          }
          id
          model {
            apiKey
          }
        }
      }
    }
  }
`
